<!-- 订单详情 -->
<template>
    <div class="flex flex-v" style="height: 100%;">
        <div class="page-header">订单详情</div>
        <div class="anchor-tabs">
            <a-anchor :affix="false" :getContainer="getScrollContainer">
                <a-anchor-link href="#item_base_info" title="基本信息" />
                <a-anchor-link href="#item_ext_info" title="收货人信息" />
                <a-anchor-link href="#item_ext_desc" title="付款信息" />
            </a-anchor>
        </div>
        <div ref="item_scroll" class="flex-1 content">
            <div id="item_base_info">
                <div class="block">
                    <div class="header">基本信息</div>
                    <div class="body">
                        基本信息
                    </div>
                </div>
            </div>
            <div id="item_ext_info">
                <div class="block">
                    <div class="header">收货人信息</div>
                    <div class="body">
                            收货人信息
                    </div>
                </div>
            </div>
            <div id="item_ext_desc">
                <div class="block">
                    <div class="header">付款信息</div>
                    <div class="body">
                            付款信息
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        components: {
        },
        data() {
            return {
                item: {
                    
                }
            }
        },
        methods: {
            getScrollContainer() {
                return this.$refs.item_scroll
            }
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .anchor-tabs {
        box-shadow: 0px 0px 4px 0px rgba(204, 204, 204, 1);
    }

    .anchor-tabs>>>.ant-anchor {
        padding: 0 !important;
    }

    .anchor-tabs>>>.ant-anchor-link {
        float: left;
        padding: 20px 15px;
    }

    .anchor-tabs>>>.ant-anchor-link-active {
        background: rgba(249, 246, 241, 1);
        color: rgba(204, 153, 90, 1);
        border-top: 2px solid rgba(204, 153, 90, 1);
    }

    .content {
        overflow: auto;
    }

    .content>div {
        padding: 30px;
    }

    .content>div:not(:last-child) {
        padding-bottom: 0;
    }

    .content>div>div {
        background: rgba(255, 255, 255, 1);
    }
</style>